import React, {
  createContext,
  useState,
  useContext,
  type PropsWithChildren,
} from 'react';

interface LocaleContextType {
  locale: string;
  changeLocale: (lang: string) => void;
}

const LocaleContext = createContext<LocaleContextType | null>(null);

export const LocaleProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [locale, setLocale] = useState('en');

  function changeLocale(lang: string) {
    setLocale(lang);
  }

  return (
    <LocaleContext.Provider value={{ locale, changeLocale }}>
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocale = () => {
  const context = useContext(LocaleContext);
  if (!context) {
    throw new Error('useLocale must be used within an LocaleProvider');
  }
  return context;
};
