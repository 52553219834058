import React from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';

const ContactIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M5,6 L5,17.52 L19.28,17.52 L19.28,6 L5,6 Z M15.36,8 L12.14,10.5 L8.93,8 L15.36,8 Z M7,15.52 L7,9.04 L12.14,13.04 L17.29,9.04 L17.29,15.52 L7,15.52 Z" />
  </SvgIcon>
);

export default ContactIcon;
