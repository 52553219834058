import React from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';

const StoriesIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M19.38,4 L19.38,16.42 L11.75,16.42 L6.28,20.42 L6.28,16.42 L4,16.42 L4,4 L19.38,4 Z M17.38,6 L5.96,6 L5.96,14.42 L8.24,14.42 L8.24,16.42 L11.06,14.42 L17.38,14.42 L17.38,6 Z M15.03,11.05 L15.03,12.05 L8.38,12.05 L8.38,11.05 L15.03,11.05 Z M15.03,9.15 L15.03,10.15 L8.38,10.15 L8.38,9.15 L15.03,9.15 Z M15.03,7.36 L15.03,8.36 L8.38,8.36 L8.38,7.36 L15.03,7.36 Z" />
  </SvgIcon>
);

export default StoriesIcon;
