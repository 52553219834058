import { useStaticQuery, graphql } from 'gatsby';

import { useLocale } from '../hooks/locale';

const query = graphql`
  query useTranslations {
    rawData: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            by
            published

            topbarCta

            footerAbout
            footerChangelog
            footerCompany
            footerContact
            footerDocs
            footerFaq
            footerGuidebook
            footerHeader
            footerHelp
            footerJobs
            footerNews
            footerOS
            footerPrivacy
            footerSignupCta
            footerSignupPrompt
            footerSocial
            footerStore
            footerTeam
            footerText
            footerTos

            newsletterLabel
            newsletterButton
            newsletterMessage

            storyWhen
            storyWhere
            storyWho
            storyPublished

            jobAll
            jobApply
            jobJoinAs
            jobPublished
            jobWhen
            jobWhere

            share
            shareNewsEmailBody
            shareNewsEmailSubject
            shareNewsTwitterBody
            shareJobEmailBody
            shareJobEmailSubject
            shareJobTwitterBody

            otherStories
            otherArticles

            next
            prev
            of
          }
        }
      }
    }
  }
`;

function useTranslations() {
  // Grab the locale (passed through context) from the Locale Provider
  // through useLocale() hook
  const { locale } = useLocale();
  // Query the JSON files in <rootDir>/i18n/translations
  const { rawData } = useStaticQuery<Queries.useTranslationsQuery>(query);

  // Simplify the response from GraphQL
  const simplified = rawData.edges.map((item) => ({
    name: item.node.name,
    translations: item.node.translations,
  }));

  // Only return translations for the current locale
  const { translations } = simplified.filter((lang) => lang.name === locale)[0];

  return translations;
}

export default useTranslations;
