import React from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';

const AboutIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M8,16.1617101 L7.41,16.1617101 L7.35,16.1617101 C4.92805804,15.8204361 3.09691714,13.7956573 3,11.3517101 L6.21,11.1317101 C6.2525726,12.0082318 6.89588657,12.7387044 7.76,12.8917101 C8.76,12.9817101 9.21,12.5017101 10.24,11.1217101 C11.0982141,9.69562051 12.444969,8.62958765 14.03,8.12171009 C15.6153262,7.76548165 17.2748901,8.20181642 18.48,9.29171009 C19.739231,10.3925316 20.3813955,12.0390116 20.2,13.7017101 L17,13.3417101 C17.0604672,12.7222117 16.8184476,12.1115776 16.35,11.7017101 C15.9282135,11.3108927 15.3405832,11.1536922 14.78,11.2817101 C14.06,11.4617101 13.56,12.0817101 12.78,13.0917101 C11.83,14.4017101 10.51,16.1617101 8,16.1617101 Z" />
  </SvgIcon>
);

export default AboutIcon;
