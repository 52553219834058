import { createTheme } from '@mui/material/styles';
import { darken, lighten } from 'polished';

import '../assets/fonts/manrope.css';

import setType from '../utils/setType';
import colors from './colors';

export const fonts = {
  body: 'Manrope, Helvetica, Arial, sans-serif',
};

let theme = createTheme({});

theme = createTheme(theme, {
  mixins: {
    toolbar: {
      minHeight: theme.spacing(12),
      '@media (min-width: 0px) and (orientation: landscape)': {
        minHeight: theme.spacing(14),
      },
      [theme.breakpoints.up('md')]: {
        minHeight: theme.spacing(16),
      },
    },
  },
  palette: {
    background: {
      paper: '#FFFFFF',
      default: colors.grey,
      dark: '#000',
    },
    primary: {
      contrastText: theme.palette.getContrastText(colors.blue),
      dark: darken(0.1, colors.blue),
      light: lighten(0.5, colors.blue),
      main: colors.blue,
    },
    secondary: {
      contrastText: theme.palette.getContrastText(colors.green),
      dark: darken(0.1, colors.green),
      light: lighten(0.5, colors.green),
      main: colors.green,
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        colorDefault: {
          backgroundColor: colors.grey,
          color: theme.palette.getContrastText(colors.grey),
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: '#A3D3B8',
          '& img': {
            mixBlendMode: 'multiply',
          },
        },
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        outlined: {
          borderWidth: '2px',
          borderColor: colors.black,
          color: 'inherit',
          '&:hover': {
            borderWidth: '2px',
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        body: {
          background-color: colors.grey;
        }
      `,
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'inherit',
          '&.Mui-focused': {
            color: 'inherit',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
        input: {
          color: 'inherit',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '@media (hover: none)': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'inherit',
            },
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'inherit',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'inherit',
          },
          '&:hover': {
            borderColor: 'inherit',
          },
        },
        notchedOutline: {
          borderColor: 'inherit',
        },
        input: {
          color: 'inherit',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        square: true,
      },
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: '0.75em',
        },
      },
    },
    PrivateNotchedOutline: {
      styleOverrides: {
        root: {
          color: 'inherit',
          borderColor: 'inherit',
          borderWidth: 2,
        },
      },
    },
  },
  shape: {
    borderRadius: 0,
  },
  shadows: theme.shadows.map((shadow, i) =>
    i === 1 ? '0 5px 10px 0 rgba(0,0,0,0.1)' : shadow
  ),
  typography: {
    fontFamily: fonts.body,
    fontWeight: '500',
    button: {
      ...setType(200),
      fontFamily: fonts.body,
      fontWeight: '600',
      letterSpacing: '0.05em',
    },
    h1: { ...setType(900), fontFamily: fonts.body, fontWeight: '700' },
    h2: { ...setType(800), fontFamily: fonts.body, fontWeight: '700' },
    h3: { ...setType(700), fontFamily: fonts.body, fontWeight: '700' },
    h4: {
      ...setType(600),
      fontFamily: fonts.body,
      fontWeight: '700',
    },
    h5: { ...setType(600), fontFamily: fonts.body, fontWeight: '500' },
    h6: {
      ...setType(200),
      fontFamily: fonts.body,
      fontWeight: '700',
      letterSpacing: '0.05em',
    },
    subtitle1: {
      ...setType(600),
      fontFamily: fonts.body,
      fontWeight: '500',
      letterSpacing: '0.05em',
    },
    subtitle2: {
      ...setType(500),
      fontFamily: fonts.body,
      fontWeight: '500',
      letterSpacing: '0.05em',
    },
    body1: { ...setType(300), fontWeight: '500', letterSpacing: '0.05em' },
    body2: { ...setType(200), fontWeight: '500' },
    overline: {
      ...setType(100),
      fontFamily: fonts.body,
      fontWeight: '600',
      letterSpacing: '0.05em',
    },
    caption: {
      ...setType(100),
      fontFamily: fonts.body,
      fontWeight: '400',
      letterSpacing: '0.05em',
    },
  },
});

export default theme;
