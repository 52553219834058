import { fluidify } from '@src/utils/fluidify';

const sizes = {
  100: ['12px', '13px', '1.6em'],
  200: ['14px', '16px', '1.6em'],
  300: ['15px', '17px', '1.6em'],
  400: ['16px', '19px', '1.6em'],
  500: ['17px', '21px', '1.4em'],
  600: ['19px', '24px', '1.4em'],
  700: ['24px', '36px', '1.3em'],
  800: ['27px', '60px', '1.2em'],
  900: ['30px', '90px', '1.1em'],
};

export default function setType(size: keyof typeof sizes) {
  const range = {
    min: sizes[size][0],
    max: sizes[size][1],
  };

  return {
    ...fluidify('fontSize', range),
    [`lineHeight`]: sizes[size][2],
  };
}
