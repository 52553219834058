import React from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';

const PartnersIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M10.71,11.6088356 L10.77,11.9188356 C11.2061875,14.3548353 13.3252568,16.1281692 15.8,16.1281692 C18.2747432,16.1281692 20.3938125,14.3548353 20.83,11.9188356 L20.83,11.9188356 L20.83,11.6088356 L23.37,11.6088356 L23.31,12.0288356 C22.8291379,15.8380096 19.5894055,18.6944772 15.75,18.6944772 C11.9105945,18.6944772 8.67086207,15.8380096 8.19,12.0288356 L8.19,12.0288356 L8.13,11.6088356 L10.71,11.6088356 Z M8.56,6 C12.3754432,6 15.6043579,8.81839646 16.12,12.5988356 L16.12,12.5988356 L16.18,13.0288356 L13.65,13.0288356 L13.59,12.7188356 C13.1538125,10.282836 11.0347432,8.50950203 8.56,8.50950203 C6.08525682,8.50950203 3.96618749,10.282836 3.53,12.7188356 L3.53,12.7188356 L3.53,13.0288356 L1,13.0288356 L1,12.5988356 C1.51564209,8.81839646 4.74455678,6 8.56,6 Z" />
  </SvgIcon>
);

export default PartnersIcon;
